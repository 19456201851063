<template>
  <div class="public-body">
    <div class="public-body__block  public-body__block--decor">
      <img src="@/assets/img/mkp-logo.svg" alt="logo" />
    </div>
    <div class="public-body__block">
      <div class="public-body__icon">
        <img src="@/assets/img/public/success-icon.svg" alt="logo" />
      </div>
      <div class="public-body__headline">Ваша заявка успешно отправлена</div>
      <div class="public-body__text">
        Образец Вашей карты. Карта будет изготовлена в течение 1 рабочего дня.
      </div>
      <div class="public-body__result public-body__result--col">
        <img :src="imageLink()" alt="" />
      </div>
      <div class="public-body__text">
        Во время обработки Вашей заявки Вы можете произвести оплату через ГАЗПРОМБАНК, для этого нажмите кнопку <b>Оплатить</b>.
      </div>
      <div class="public-body__text">
        <a :href="payLink()" class="button">Оплатить</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardSuccess',
  mounted () {
    if (this.$route.params.hash) {
      this.payLink();
      this.imageLink();
    } else {
      //this.$router.push({ name: 'card' });
    }
  },
  methods: {
    payLink () {
      return `${process.env.VUE_APP_PUBLIC_URL}card/pay/${this.$route.params.hash}`;
    },
    imageLink () {
      return this.$route.params.image;
    }
  }
};
</script>
